.dark-mode-toggle-wrapper {
    --shrink-by: 0.1;
    --toggle-size: calc(500px * var(--shrink-by)* 1.25);
    --toggle-height: calc(200px * var(--shrink-by)* 1.25);
    --toggle-radius: calc(200px * var(--shrink-by));
    --toggle-button-size: calc(180px * var(--shrink-by) * 1.25);
    --toggle-icon-size: calc(120px * var(--shrink-by));
    width: 100%;
    display: flex;
    justify-content: flex-end;

}


.dark-mode-toggle-wrapper body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dark-mode-toggle-wrapper label {
    width: calc(var(--toggle-size) * 1.03);
    height: calc(var(--toggle-height) * 1.1);
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: var(--toggle-radius);
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
}

.dark-mode-toggle-wrapper label:after {
    content: "";
    width: var(--toggle-button-size);
    height: var(--toggle-button-size);
    position: absolute;
    top: 2px;
    left: 5px;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: var(--toggle-button-size);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.dark-mode-toggle-wrapper label svg {
    position: absolute;
    width: var(--toggle-icon-size);
    top: 7px;
    z-index: 100;
}


.dark-mode-toggle-wrapper input {
    width: 0;
    height: 0;
    visibility: hidden;
}

.dark-mode-toggle-wrapper input:checked+label {
    background: #242424;
}

.dark-mode-toggle-wrapper input:checked+label:after {
    left: calc(var(--toggle-size) - var(--toggle-button-size) - 6px);
    background: linear-gradient(180deg, #777, #3a3a3a);
}

/* Initial positions (unchanged) */
.dark-mode-toggle-wrapper label svg.sun {
    left: calc(var(--toggle-size) - var(--toggle-icon-size) - 40px);
    /* Positioned on the right */
    fill: #fff;
    transition: 0.3s;
}

.dark-mode-toggle-wrapper label svg.moon {
    left: 40px;
    /* Positioned on the left */
    fill: #7e7e7e;
    transition: 0.3s;
}

/* Positions when checked (Only changes the colors) */
.dark-mode-toggle-wrapper input:checked+label svg.sun {
    fill: #7e7e7e;
}

.dark-mode-toggle-wrapper input:checked+label svg.moon {
    fill: #fff;
}

.dark-mode-toggle-wrapper input:checked+label+.background {
    background: #242424;
}

.dark-mode-toggle-wrapper input:active:after {
    width: calc(var(--toggle-button-size) + 80px);
}

.dark-mode-toggle-wrapper .background {
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: -1;
    position: absolute;
    transition: 0.3s;
}