.SiteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 10% 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
}

.SiteHeader-branding h1 a {
    margin: 0;
    cursor: pointer;
    color: var(--primary-text-color);
    text-decoration: none;
    line-height: 1.4;
}

.SiteHeader-branding h1 img {
    border-radius: 25%;
    margin: -4px 0.5rem 0 0;
    height: 44px;
    width: 44px;
    vertical-align: middle;
}

.SiteHeader-branding p {
    margin: 0.5rem 0;
}

.SiteHeader-nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 1rem;
}

.SiteHeader-nav a:hover {
    background-color: var(--link-color);
    color: var(--primary-bg-color);
}

.SiteHeader-nav li {
    position: relative;
}

.SiteHeader-nav a {
    text-decoration: none;
    color: var(--link-color);
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: var(--primary-bg-color);
}

.SiteHeader-nav ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    background-color: var(--primary-bg-color);
    flex-direction: column;
    white-space: nowrap;
    padding: 20px 15px;
    gap: 0.5rem;
}

.SiteHeader-nav li:hover > ul {
    display: flex;
}

.SiteHeader-nav ul ul li {
    width: 100%;
}

@media (max-width: 676px) {
    .SiteHeader {
        flex-direction: column;
        padding-top: 2rem;
    }

    .SiteHeader-nav ul {
        justify-content: center;
    }

    .SiteHeader-nav {
        margin-top: 1rem;
    }
}
