/* Default (Dark Mode) CSS Variables */
:root {
    --primary-bg-color: #282c34;
    --primary-text-color: white;
    --link-color: #61dafb;
    --secondary-bg-color: #2f333b;
    --hover-bg-color: #373c44;
}

/* Light Mode CSS Variables */
[data-theme="light"] {
    --primary-bg-color: white;
    --primary-text-color: black;
    --link-color: #282c34;
    --secondary-bg-color: #d1d1d1;
    --hover-bg-color: #e6e6e6;
}

html {
    background-color: var(--primary-bg-color);
}

.WordcraftStudios {
    text-align: left;
    padding: 0 10% 2%;
    min-height: 100vh;
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
}

.WordcraftStudios-main {
    margin-top: 20vh;
    font-size: calc(10px + 1vmin);
}
